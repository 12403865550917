<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col>
        <h1>
          Rooms
        </h1>
      </b-col>

      <b-col cols="auto">
        <b-button class="mb-5 add-button" @click="addRoom()">
          Add
        </b-button>
      </b-col>
    </b-row>
    <vuetable
      ref="roomstable"
      :api-mode="false"
      :fields="fields"
      :data="rooms"
      @vuetable:pagination-data="onPaginationData"
    >
      <template slot="actions" scope="props">
        <font-awesome-icon
          icon="edit"
          class="action-icon"
          data-toggle="tooltip"
          data-placement="top"
          title="Edit item"
          @click="editRoom(props.rowData)"
          ref="btnEdit"
        ></font-awesome-icon>
        <font-awesome-icon
          icon="trash"
          class="ml-2 action-icon"
          data-toggle="tooltip"
          data-placement="top"
          title="Remove item"
          @click="deleteRoom(props.rowData)"
        ></font-awesome-icon>
      </template>
    </vuetable>
    <vuetable-pagination
      ref="pagination"
      @vuetable-pagination:change-page="onChangePage"
    ></vuetable-pagination>
  </b-overlay>
</template>
<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";

export default {
  components: {
    Vuetable,
    VuetablePagination,
  },
  data() {
    return {
      isLoading: false,
      fields: ["name", "actions"],
    };
  },
  computed: {
    rooms() {
      return this.$store.getters["Rooms/all"];
    },
  },
  methods: {
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.roomstable.changePage(page);
    },
    async doFetchAll() {
      this.isLoading = true;
      await this.$store.dispatch("Rooms/fetchAll");
      this.isLoading = false;
    },
    editRoom(room) {
      this.$router.push({ name: "room-detail", params: { id: room.id } });
    },
    addRoom() {
      this.$router.push({ name: "room-create" });
    },
    async deleteRoom(room) {
      this.isLoading = true;
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to remove ${room.name}?`,
        {
          title: "Confirm delete",
          okVariant: "danger",
          centered: true,
        }
      );
      if (isConfirmed) {
        await this.$store.dispatch("Rooms/delete", room.id);
        return this.doFetchAll();
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.doFetchAll();
  },
};
</script>
<style lang="scss"></style>
